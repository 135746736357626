import React, { useState, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';

import { FAQ } from '../General/FAQ';

const useStyles = makeStyles((theme) => ({
	faqSection: {
		paddingTop: '14rem',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '7rem',
		},
	},

	headerContainer: {
		paddingBottom: '4rem',
		[theme.breakpoints.down('sm')]: {
			paddingBottom: '2rem',
		},
	},
	header: {
		fontWeight: 700,
		color: theme.workwaveBlue,
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem',
		},
	},
	subHeader: {
		color: '#5A7184',
	},
}));

export const FeatureFAQ = ({ faq, header }) => {
	const classes = useStyles();
	const faqRef = useRef(null);
	const [expanded, setExpanded] = useState(0);

	return (
		<div style={{}} className={classes.faqSection}>
			<Grid
				container
				direction='column'
				justifyContent='center'
				className={classes.headerContainer}>
				<Grid item>
					<Typography
						variant='h2'
						style={{ textAlign: 'center' }}
						className={classes.header}>
						{header}
					</Typography>
				</Grid>
			</Grid>
			{faq.map((faq, index) => (
				<FAQ
					faq={faq}
					index={index}
					expanded={expanded}
					setExpanded={setExpanded}
					faqRef={faqRef}
				/>
			))}
		</div>
	);
};
